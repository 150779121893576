import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.css";

const Modal = ({ children, show }) =>
  show && (
    <div className={styles.wrapper}>
      <div className={styles.modal}>{children}</div>
    </div>
  );

Modal.defaultProps = {
  show: false,
  children: null,
};

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

export default Modal;
