import RevieveSDK from "@revieve/sdk";
import { useRef, useState, useEffect } from "react";

const MASKS = {
  foreHead: RevieveSDK.masks.skincare.FOREHEAD_WORRY_LINES,
  leftEye: RevieveSDK.masks.skincare.LEFT_SIDE_EYE_CROWS_FEET,
  rightEye: RevieveSDK.masks.skincare.RIGHT_SIDE_EYE_CROWS_FEET,
  leftNasolabial: RevieveSDK.masks.skincare.LEFT_SIDE_NASOLABIAL,
  rightNasolabial: RevieveSDK.masks.skincare.RIGHT_SIDE_NASOLABIAL,
  lips: RevieveSDK.masks.skincare.VERTICAL_LIP_LINES,
};

const RANGES = [
  { minValue: 0, maxValue: 0.2, color: "rgb(44, 192, 205)", label: "low" },
  { minValue: 0.2, maxValue: 0.4, color: "rgb(253, 164, 98)", label: "medium" },
  { minValue: 0.4, maxValue: 1, color: "rgb(205, 44, 66)", label: "high" },
];

const partnerID = "vnydCW0AMh";
const revieveSDK = new RevieveSDK(partnerID, true);

const findByDescription = (entries, description) => entries.find(entry => entry.description === description);

const warningsToIgnore = [303, 305, 306];
const mapStatus = status =>
  Object.values(status)
    .filter(warning => !warningsToIgnore.includes(warning.idx))
    .map(({ description }) => description);

const getScore = intensity => {
  const maxScore = 10;
  return parseFloat((intensity * maxScore).toFixed(2));
};

const getDoseFrom = score => {
  if (score < 4) return 0;
  if (score < 8) return 1;
  else return 2;
};

const mapAreas = ({ measurement_locations: areas = [] }) =>
  Object.entries(MASKS).reduce((foundAreas, [mask, area]) => {
    const areaResults = findByDescription(areas, area);
    if (!areaResults) return foundAreas;
    const score = getScore(areaResults.value);
    const doses = getDoseFrom(score);
    return { ...foundAreas, [mask]: { score, doses } };
  }, {});

const getFaceArea = () => {
  const intensity = revieveSDK.CV.getWrinkles();
  const score = getScore(intensity);
  return { score, doses: 0 };
};

export const analyseImage = async image => {
  try {
    const components = ["wrinkles", "masks", "wrinkles_visualization", "eyes"];
    revieveSDK.setConfiguration({ components });
    await revieveSDK.CV.setImage(image);
    await revieveSDK.CV.analyzeImage();
    const wrinkleResults = findByDescription(revieveSDK.CV.getResults(), "wrinkles");
    const anonymizeImage = await revieveSDK.CV.getAnonymizeImage();
    const areas = { ...mapAreas(wrinkleResults), face: getFaceArea() };
    const warnings = mapStatus(revieveSDK.CV.getStatus());
    return {
      warnings,
      hasErrors: revieveSDK.CV.hasError() || warnings.length > 0,
      areas,
      anonymizeImage,
    };
  } catch (error) {
    return {
      warnings: [error.message],
      hasErrors: true,
    };
  }
};

export const useRevieveResults = showing => {
  const resultsElement = useRef();
  const [visible, setVisible] = useState(showing);
  const [style, setStyle] = useState({});
  const [styleOut, setStyleOut] = useState({});
  const [mask, setMask] = useState(null);

  useEffect(() => {
    revieveSDK.AR.setContainer(resultsElement.current);
    revieveSDK.AR.setScaleFactor(1.1);
  }, []);

  useEffect(() => {
    revieveSDK.AR.dehighlightAll();
    const applyMask = async () => {
      const style = await revieveSDK.AR.highlightWrinkles([mask]);
      setStyle(style.in);
      setStyleOut(style.out);
    };
    if (visible) revieveSDK.AR.highlightWrinklesIndividually(RANGES, Object.values(MASKS));
    if (mask) applyMask();
  }, [visible, mask]);

  const zoomIn = async area => {
    const mask = MASKS[area];
    setMask(mask);
  };

  const zoomOut = async () => {
    setMask(null);
    setStyle(styleOut);
  };

  const setVisualization = setVisible;

  return [resultsElement, { visible, setVisualization, zoomIn, zoomOut, style, ranges: RANGES }];
};
