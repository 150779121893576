import React from "react";
import PropTypes from "prop-types";
import backArrowIcon from "./icons/back-arrow.svg";
import restartIcon from "./icons/restart.svg";
import hideWrinklesIcon from "./icons/hide-wrinkles.svg";
import showWrinklesIcon from "./icons/show-wrinkles.svg";
import saveIcon from "./icons/save.svg";

import styles from "./styles.module.css";

const ICONS = {
  backArrow: backArrowIcon,
  restart: restartIcon,
  hideWrinkles: hideWrinklesIcon,
  showWrinkles: showWrinklesIcon,
  save: saveIcon,
};

const WrinkleSwitch = ({ show, onChange }) => (
  <div className={styles.rightButton} onClick={() => onChange(!show)}>
    {show ? (
      <img className={styles.showWrinklesIcon} src={ICONS.hideWrinkles} alt="show wrinkles" />
    ) : (
      <img className={styles.hideWrinklesIcon} src={ICONS.showWrinkles} alt="hide wrinkles" />
    )}
  </div>
);

WrinkleSwitch.propTypes = {
  show: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const TopBar = ({
  backIcon,
  onGoBack,
  onSave,
  title,
  showAnalysisResultWrinklesSwitch,
  showAnalysisResultWrinkles,
  onChangeAnalysisResultWrinkles,
  showSaveButton,
}) => (
  <div className={styles.wrapper}>
    {onGoBack && (
      <div className={styles.leftButton} onClick={onGoBack}>
        <img className={styles.leftIcon} src={ICONS[backIcon]} alt="back" />
      </div>
    )}
    <div className={styles.title}>{title}</div>
    <div className={styles.rightArea}>
      {showAnalysisResultWrinklesSwitch && (
        <WrinkleSwitch show={showAnalysisResultWrinkles} onChange={onChangeAnalysisResultWrinkles} />
      )}
      {showSaveButton && (
        <div className={styles.rightButton} onClick={onSave}>
          <img className={styles.saveIcon} src={ICONS.save} alt="back" />
        </div>
      )}
    </div>
  </div>
);

TopBar.defaultProps = {
  backIcon: "backArrow",
  onGoBack: null,
  onSave: () => {},
  showAnalysisResultWrinklesSwitch: false,
  showAnalysisResultWrinkles: false,
  onChangeAnalysisResultWrinkles: () => {},
  showSaveButton: false,
};

TopBar.propTypes = {
  title: PropTypes.string.isRequired,
  backIcon: PropTypes.oneOf(["backArrow", "restart"]),
  onGoBack: PropTypes.func,
  onSave: PropTypes.func,
  showAnalysisResultWrinklesSwitch: PropTypes.bool,
  showAnalysisResultWrinkles: PropTypes.bool,
  onChangeAnalysisResultWrinkles: PropTypes.func,
  showSaveButton: PropTypes.bool,
};

export default TopBar;
