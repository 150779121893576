import React from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import Button from "../../components/Button";

import "pure-react-carousel/dist/react-carousel.es.css";
import "./styles.css";

const Instructions = ({ onTakeSelfie }) => {
  const t = useTranslate();
  return (
    <>
      <CarouselProvider naturalSlideWidth={1000} naturalSlideHeight={1} totalSlides={1} className="Instructions">
        <Slider>
          <Slide index={0}>
            <div className="slide">
              <div className="text">
                <p>{t("Instructions.tip1")}</p>
                <p>{t("Instructions.tip2")}</p>
                <p>{t("Instructions.tip3")}</p>
                <p>{t("Instructions.tip4")}</p>
              </div>
              <Button onClick={onTakeSelfie}>{t("Instructions.takeSelfie")}</Button>
            </div>
          </Slide>
        </Slider>
        {/* <div className="dots">
        <DotGroup />
      </div> */}
      </CarouselProvider>
    </>
  );
};
Instructions.defaultProps = {
  onTakeSelfie: () => {},
};

Instructions.propTypes = {
  onTakeSelfie: PropTypes.func,
};

export default Instructions;
