import React from "react";
import ReactDOM from "react-dom";
import { I18n } from "react-polyglot";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import messages from "./locale/";

const init = ({ locale = "en", htmlElement }) => {
  ReactDOM.render(
    <I18n locale={locale} messages={messages[locale]}>
      <App />
    </I18n>,
    htmlElement,
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

window.initRevieve = init;
