import React from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";

import styles from "./styles.module.css";
import productIcon from "./product.svg";

const Info = ({ recommendedDoses, section, ranges }) => {
  const t = useTranslate();
  const isFace = section === "face";
  return (
    <div className={styles.card}>
      {isFace ? (
        ranges.map(range => {
          return (
            <div key={range.label} className={styles.rangeInfo}>
              <div className={styles.rangeColor} style={{ backgroundColor: range.color }}></div>
              <div>{t(`AnalysisResult.${range.label}`)}</div>
            </div>
          );
        })
      ) : (
        <>
          <div className={styles.areaName}>{t(`AnalysisResult.${section}`)}</div>
          <img className={styles.icon} src={productIcon} alt="product" />
          <div className={styles.doses}>
            <span className={styles.dosesNumber}>{recommendedDoses}</span> {t(`AnalysisResult.doses`, recommendedDoses)}
          </div>
        </>
      )}
    </div>
  );
};

Info.defaultProps = {
  recommendedDoses: 0,
};

Info.propTypes = {
  section: PropTypes.oneOf(["face", "foreHead", "leftEye", "rightEye", "leftNasolabial", "rightNasolabial", "lips"])
    .isRequired,
  recommendedDoses: PropTypes.number,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Info;
