import React from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import Button from "../../components/Button";

import styles from "./styles.module.css";

const SelfieError = ({ image, onRetry, errors }) => {
  const t = useTranslate();
  return (
    <>
      <img src={image} className={styles.image} alt="selfie" />
      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          {errors.map((error, key) => (
            <div key={key} className={styles.error}>
              {error}
            </div>
          ))}
          <div className={styles.action}>
            <Button onClick={onRetry} theme="error">
              {t("SelfieError.retake")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

SelfieError.defaultProps = {
  onRetry: () => {},
  errors: [],
};

SelfieError.propTypes = {
  image: PropTypes.string.isRequired,
  onRetry: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default SelfieError;
