import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import { ReactComponent as OkIcon } from "./ok.svg";

import styles from "./styles.module.css";

const SelfieConfirmation = ({ image, onConfirm, warnings }) => {
  const t = useTranslate();
  const [showWarnings, setShowWarnings] = useState(warnings.length > 0);
  const hideWarnings = () => setShowWarnings(false);
  return (
    <>
      <img src={image} className={styles.image} alt="selfie" />
      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <div className={styles.action}>
            <OkIcon className={styles.icon} />
            <Button onClick={onConfirm} theme="confirm">
              {t("SelfieConfirmation.continue")}
            </Button>
          </div>
        </div>
      </div>
      <Modal show={showWarnings}>
        {warnings.map((error, key) => (
          <div key={key} className={styles.warning}>
            {error}
          </div>
        ))}
        <div className={styles.warningActions}>
          <Button onClick={hideWarnings} theme="normal">
            {t("SelfieConfirmation.continue")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

SelfieConfirmation.defaultProps = {
  onConfirm: () => {},
  warnings: [],
};

SelfieConfirmation.propTypes = {
  image: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  warnings: PropTypes.arrayOf(PropTypes.string),
};

export default SelfieConfirmation;
