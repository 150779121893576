import React from "react";
import PropTypes from "prop-types";
import combineStyles from "classnames";

import styles from "./styles.module.css";
import leftEyeIcon from "./icons/eyes_left.svg";
import rightEyeIcon from "./icons/eyes_right.svg";
import faceIcon from "./icons/face.svg";
import foreheadIcon from "./icons/forehead.svg";
import lipsIcon from "./icons/lips.svg";
import leftNasolabialIcon from "./icons/nasolabial_left.svg";
import rightNasolabialIcon from "./icons/nasolabial_right.svg";

const ICONS = {
  face: faceIcon,
  foreHead: foreheadIcon,
  leftEye: leftEyeIcon,
  rightEye: rightEyeIcon,
  leftNasolabial: leftNasolabialIcon,
  rightNasolabial: rightNasolabialIcon,
  lips: lipsIcon,
};

const TabBar = ({ selected, onSelect, showDosesInfo }) => (
  <div className={styles.wrapper}>
    {["face", "foreHead", "leftEye", "rightEye", "lips", "leftNasolabial", "rightNasolabial"].map(tabName => (
      <div
        key={tabName}
        className={combineStyles(styles.tab, selected === tabName ? styles.selected : null)}
        onClick={() => onSelect(tabName)}
      >
        <img className={styles.icon} src={ICONS[tabName]} alt={tabName} />
      </div>
    ))}
  </div>
);

TabBar.defaultProps = {
  onSelect: () => {},
};

TabBar.propTypes = {
  selected: PropTypes.oneOf(["face", "foreHead", "leftEye", "rightEye", "leftNasolabial", "rightNasolabial", "lips"])
    .isRequired,
  onSelect: PropTypes.func,
};

export default TabBar;
