export default {
  language: "English",
  App: {
    takeSelfieError: "Error accessing to the camera",
  },
  pageTitles: {
    instructions: "INSTRUCTIONS",
    takeSelfie: "TAKE A SELFIE",
    analysingSelfie: "ANALYSING SELFIE",
    selfieWithErrors: "SELFIE ERROR",
    selfieConfirmation: "TAKE A SELFIE",
    analysisResults: "SKIN ANALYSIS",
    save: "SAVE",
  },
  Instructions: {
    takeSelfie: "TAKE SELFIE",
    tip1: "Best light comes from the direction of the camera",
    tip2: "Natural light works the best",
    tip3: "Avoid hard shadows or overexposure to light",
    tip4: "Face directly to the camera",
  },
  TakeSelfie: {
    helpText: "Tap anywhere to take the selfie",
  },
  SelfieError: {
    retake: "RETAKE",
  },
  SelfieConfirmation: {
    continue: "CONTINUE",
  },
  AnalysisResult: {
    doses: "dose |||| doses",
    foreHead: "Forehead",
    leftEye: "Left crow’s feet",
    rightEye: "Right crow’s feet",
    leftNasolabial: "Left nasolabial",
    rightNasolabial: "Right nasolabial",
    lips: "Upper lip area",
    low: "Low",
    medium: "Medium",
    high: "High",
  },
  Save: {
    save: "SAVE",
    cancel: "CANCEL",
    userID: "User identification",
    userIDPlaceholder: "Type the user identification",
  },
};
