import React from "react";
import PropTypes from "prop-types";
import loadingIcon from "./loading.gif";

import styles from "./styles.module.css";

const AnalysingSelfie = ({ image }) => (
  <>
    <img src={image} className={styles.image} alt="selfie" />
    <div className={styles.loading}>
      <img src={loadingIcon} className={styles.loadingIcon} alt="loading" />
    </div>
  </>
);

AnalysingSelfie.propTypes = {
  image: PropTypes.string.isRequired,
};

export default AnalysingSelfie;
