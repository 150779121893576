import React from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";

import styles from "./styles.module.css";

const Actions = ({ onTakeSelfie }) => {
  const t = useTranslate();
  return (
    <div className={styles.wrapper} onClick={onTakeSelfie}>
      <div className={styles.guideline}></div>
      <div className={styles.helpText}>{t("TakeSelfie.helpText")}</div>
    </div>
  );
};

Actions.propTypes = {
  onTakeSelfie: PropTypes.func.isRequired,
};

export default Actions;
