import React, { useState } from "react";
import { useTranslate } from "react-polyglot";
import { analyseImage } from "./services/revieve";
import { downloadResults } from "./services/downloader";
import TopBar from "./components/TopBar";
import TakeSelfie from "./pages/TakeSelfie";
import Instructions from "./pages/Instructions";
import AnalysingSelfie from "./pages/AnalysingSelfie";
import SelfieError from "./pages/SelfieError";
import SelfieConfirmation from "./pages/SelfieConfirmation";
import AnalysisResults from "./pages/AnalysisResults";

import "./App.css";
import "./fonts/OpenSans-Regular.ttf";

const PAGES = {
  instructions: {
    id: "instructions",
    backPage: null,
    title: "instructions",
  },
  takeSelfie: {
    id: "takeSelfie",
    backPage: "instructions",
    title: "takeSelfie",
  },
  analysingSelfie: {
    id: "analysingSelfie",
    backPage: null,
    title: "analysingSelfie",
  },
  selfieWithErrors: {
    id: "selfieWithErrors",
    backPage: "takeSelfie",
    title: "selfieWithErrors",
  },
  selfieConfirmation: {
    id: "selfieConfirmation",
    backPage: "takeSelfie",
    title: "selfieConfirmation",
  },
  analysisResults: {
    id: "analysisResults",
    backPage: "instructions",
    title: "analysisResults",
    topBar: {
      backIcon: "restart",
      showAnalysisResultWrinklesSwitch: true,
      showSaveButton: true,
    },
  },
  save: {
    id: "save",
    backPage: "analysisResults",
    title: "save",
  },
};

const initPage = PAGES.instructions;

const App = () => {
  const t = useTranslate();
  const [currentPage, setCurrentPage] = useState(initPage);
  const [selfie, setSelfie] = useState(null);
  const [cvResult, setCvResult] = useState({});
  const [showAnalysisResultWrinkles, setShowAnalysisResultWrinkles] = useState(true);
  const [showAnalysisResultSaveModal, setShowAnalysisResultSaveModal] = useState(false);

  const goTo = page => setCurrentPage(page);

  const goBack = () => goTo(PAGES[currentPage.backPage]);

  const handleStartSelfieProcess = () => goTo(PAGES.takeSelfie);

  const handleTakeSelfieError = () => {
    alert(t("App.takeSelfieError"));
    goBack();
  };

  const handleTakeSelfie = async image => {
    setSelfie({ image, date: new Date() });
    goTo(PAGES.analysingSelfie);
    const cvResult = await analyseImage(image);
    setCvResult(cvResult);
    const nextPage = cvResult.hasErrors ? PAGES.selfieWithErrors : PAGES.selfieConfirmation;
    goTo(nextPage);
  };

  const handleRetrySelfie = () => {
    setSelfie(null);
    goTo(PAGES.takeSelfie);
  };

  const handelSelfieConfirmation = () => {
    goTo(PAGES.analysisResults);
  };

  const handleSave = async ({ userID }) => {
    setShowAnalysisResultSaveModal(false);
    downloadResults({
      userID,
      date: selfie.date,
      image: cvResult.anonymizeImage,
      areas: cvResult.areas,
    });
  };

  const { topBar: topBarCustomization = {} } = currentPage;
  return (
    <div id="App">
      <TopBar
        onGoBack={currentPage.backPage ? goBack : null}
        title={t(`pageTitles.${currentPage.title}`)}
        showAnalysisResultWrinkles={showAnalysisResultWrinkles}
        onChangeAnalysisResultWrinkles={setShowAnalysisResultWrinkles}
        onSave={() => setShowAnalysisResultSaveModal(true)}
        {...topBarCustomization}
      ></TopBar>
      {currentPage.id === PAGES.instructions.id ? (
        <Instructions onTakeSelfie={handleStartSelfieProcess} />
      ) : currentPage.id === PAGES.takeSelfie.id ? (
        <TakeSelfie onTakeSelfie={handleTakeSelfie} onError={handleTakeSelfieError} />
      ) : currentPage.id === PAGES.analysingSelfie.id ? (
        <AnalysingSelfie image={selfie.image} />
      ) : currentPage.id === PAGES.selfieWithErrors.id ? (
        <SelfieError image={selfie.image} errors={cvResult.warnings} onRetry={handleRetrySelfie} />
      ) : currentPage.id === PAGES.selfieConfirmation.id ? (
        <SelfieConfirmation image={selfie.image} onConfirm={handelSelfieConfirmation} warnings={cvResult.warnings} />
      ) : currentPage.id === PAGES.analysisResults.id ? (
        <AnalysisResults
          showWrinkles={showAnalysisResultWrinkles}
          areas={cvResult.areas}
          onSave={handleSave}
          showAnalysisResultSaveModal={showAnalysisResultSaveModal}
          onHideAnalysisResultSaveModal={() => setShowAnalysisResultSaveModal(false)}
        />
      ) : null}
    </div>
  );
};

export default App;
