import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Webcam from "react-webcam";
import Actions from "./Actions";

import styles from "./style.module.css";

const TakeSelfie = ({ onTakeSelfie, onError }) => {
  const [loaded, setLoaded] = useState(false);
  const webcam = useRef(null);

  const handleUserMediaStarts = () => setLoaded(true);

  return (
    <div className={styles.wrapper}>
      <div className={styles.videoWrapper}>
        <Webcam
          onUserMedia={handleUserMediaStarts}
          onUserMediaError={onError}
          audio={false}
          ref={webcam}
          screenshotFormat="image/jpeg"
          width={1280}
          videoConstraints={{
            width: 1280,
            height: 720,
            facingMode: "user",
          }}
          minScreenshotWidth={800}
          minScreenshotHeight={800}
          className={styles.video}
          screenshotQuality={1}
          mirrored
        />
      </div>
      {loaded && <Actions onTakeSelfie={() => onTakeSelfie(webcam.current.getScreenshot())} />}
    </div>
  );
};

TakeSelfie.defaultProps = {
  onTakeSelfie: () => {},
  onError: () => {},
};

TakeSelfie.propTypes = {
  onTakeSelfie: PropTypes.func,
  onError: PropTypes.func,
};

export default TakeSelfie;
