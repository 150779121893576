import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";

import styles from "./styles.module.css";

const SaveModal = ({ show, areas, onSave, onCancel, showInfo }) => {
  const t = useTranslate();
  const [canSubmit, setCanSubmit] = useState(false);
  const [userID, setUserID] = useState("");

  const handleChangeUserInput = ({ target: { value } }) => {
    setUserID(value);
    setCanSubmit(Boolean(value));
  };

  const handleSave = () => onSave({ userID });

  return (
    <Modal show={show}>
      <div className={styles.content}>
        {showInfo &&
          Object.entries(areas).map(([area, { doses: recommendedDoses, score }]) => (
            <div key={area} className={styles.area}>
              <div>
                <b>{t(`AnalysisResult.${area}`)}: </b>
              </div>
              <div>
                {score} =&gt; {recommendedDoses} {t(`AnalysisResult.doses`, recommendedDoses)}
              </div>
            </div>
          ))}
        <div className={styles.userID}>
          <label>{t("Save.userID")}</label>
          <input
            type="text"
            className={styles.input}
            placeholder={t("Save.userIDPlaceholder")}
            value={userID}
            onChange={handleChangeUserInput}
          ></input>
        </div>
      </div>
      <div className={styles.actions}>
        <Button onClick={onCancel} theme="error">
          {t("Save.cancel")}
        </Button>
        <Button onClick={handleSave} theme="normal" disabled={!canSubmit}>
          {t("Save.save")}
        </Button>
      </div>
    </Modal>
  );
};

SaveModal.defaultProps = {
  show: false,
  onSave: () => {},
  onCancel: () => {},
};

SaveModal.propTypes = {
  show: PropTypes.bool,
  areas: PropTypes.shape({
    foreHead: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    leftEye: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    rightEye: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    leftNasolabial: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    rightNasolabial: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    lips: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
  }).isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  showInfo: PropTypes.bool.isRequired,
};

export default SaveModal;
