import JSZip from "jszip";
import Papa from "papaparse";
import formatDate from "dateformat";
import { saveAs } from "file-saver";

const getImageContent = image => {
  const idx = image.indexOf("base64,") + "base64,".length; // or = 28 if you're sure about the prefix
  return image.substring(idx);
};

const mapAreas = areas => Object.entries(areas).map(([area, { score, doses }]) => ({ area, score, doses }));

export const downloadResults = async ({ userID, image, date, areas }) => {
  const zip = new JSZip();
  const timeFingerprint = formatDate(date, "yyyymmddHHMM");
  const fileName = `${userID}-${timeFingerprint}`;

  const base64Image = getImageContent(image);
  const csvFile = Papa.unparse(mapAreas(areas), { delimiter: ";" });

  zip.file(`${fileName}.png`, base64Image, { base64: true, date });
  zip.file(`${fileName}.csv`, csvFile, { date });

  const zipFile = await zip.generateAsync({ type: "blob" });
  saveAs(zipFile, `${fileName}.zip`);
};
