import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRevieveResults } from "../../services/revieve";
import TabBar from "./TabBar";
import Info from "./Info";
import SaveModal from "./SaveModal";

import styles from "./styles.module.css";

const AnalysisResults = ({
  showWrinkles,
  areas,
  showAnalysisResultSaveModal,
  onHideAnalysisResultSaveModal,
  showDosesInfo,
  onSave,
}) => {
  const [tab, selectTab] = useState("face");
  const [resultsElement, { setVisualization, zoomIn, zoomOut, style, ranges }] = useRevieveResults(showWrinkles);

  const isFace = tab === "face";
  const recommendedDoses = !isFace ? areas[tab].doses : 0;
  const showInfo = !showAnalysisResultSaveModal && (isFace || showDosesInfo);

  useEffect(() => {
    setVisualization(showWrinkles);
  }, [showWrinkles, setVisualization]);

  useEffect(() => {
    if (isFace) zoomOut();
    else zoomIn(tab);
  }, [tab, zoomIn, zoomOut, isFace]);

  return (
    <>
      <div className={styles.arContainer}>
        <div ref={resultsElement} className={styles.resultsElement} style={style} />
      </div>
      <div className={styles.bottomArea}>
        {showInfo && <Info section={tab} recommendedDoses={recommendedDoses} ranges={ranges} />}
        <TabBar selected={tab} onSelect={selectTab} />
      </div>
      <SaveModal
        show={showAnalysisResultSaveModal}
        areas={areas}
        onSave={onSave}
        onCancel={onHideAnalysisResultSaveModal}
        showInfo={showDosesInfo}
      />
    </>
  );
};

AnalysisResults.defaultProps = {
  showWrinkles: true,
  showAnalysisResultSaveModal: false,
  onHideAnalysisResultSaveModal: () => {},
  showDosesInfo: false,
  onSave: () => {},
};

AnalysisResults.propTypes = {
  showWrinkles: PropTypes.bool,
  showAnalysisResultSaveModal: PropTypes.bool,
  onHideAnalysisResultSaveModal: PropTypes.func,
  showDosesInfo: PropTypes.bool,
  onSave: PropTypes.func,
  areas: PropTypes.shape({
    foreHead: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    leftEye: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    rightEye: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    leftNasolabial: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    rightNasolabial: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
    lips: PropTypes.shape({ doses: PropTypes.number.isRequired, score: PropTypes.number.isRequired }),
  }).isRequired,
};

export default AnalysisResults;
