export default {
  language: "French",
  pageTitles: {
    takeSelfie: "PRENDRE UN SELFIE",
    analysingSelfie: "ANALYSE DE SELFIE",
    selfieWithErrors: "SELFIE ERROR",
    selfieConfirmation: "PRENDRE UN SELFIE",
    analysisResults: "SKIN ANALYSIS",
  },
  TakeSelfie: {
    helpText: "Appuyez n'importe où pour prendre le selfie",
  },
  SelfieError: {
    retake: "REPRENDRE",
  },
  SelfieConfirmation: {
    continue: "CONTINUEZ",
  },
};
