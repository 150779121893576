import React from "react";
import PropTypes from "prop-types";
import combineStyles from "classnames";

// eslint-disable-next-line
import styles from "./styles.module.css";

const Button = ({ onClick, children, theme, disabled }) => (
  <div
    className={combineStyles(styles.button, styles[theme], disabled && styles.disabled)}
    onClick={!disabled ? onClick : undefined}
  >
    {children}
  </div>
);

Button.defaultProps = {
  onClick: null,
  children: null,
  theme: "normal",
  disabled: false,
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  theme: PropTypes.oneOf(["normal", "error", "confirm"]),
  disabled: PropTypes.bool,
};

export default Button;
